<script setup lang="ts">
  const { getImage } = useAssets()
  export interface UIAvatarProperties {
    avatar?: string
    path?: string
    width?: string
  }

  const props = withDefaults(defineProps<UIAvatarProperties>(), {
    avatar: '',
    width: '272px'
  })

  const emit = defineEmits(['click'])

  const noAvatar = ref(false)
  noAvatar.value = props.avatar.length === 0
</script>

<template>
  <div class="avatar" :style="`width: ${width};`">
    <svg v-if="!noAvatar" xmlns="http://www.w3.org/2000/svg" width="250" height="213" viewBox="0 0 213 213" fill="none">
      <circle cx="115" cy="106" r="106.327" fill="#98001D" fill-opacity="0.6"/>
    </svg>
    <div class="avatar-background--circle" :class="{'no-avatar': noAvatar}" @click="$emit('click', $event)">
      <img :src="getImage(`${avatar}.png`)" :alt="`${avatar}`" class="image">
<!--      <ButtonIcon :icon="noAvatar ? 'plus':'minus'" :backdrop="true" class="avatar-button"/>-->
      <ButtonImage v-if="noAvatar" :circle="true" :url="$imageAssets['btn_plus']" class="avatar-button" :image-style="{width: '88px', height: '88px'}"/>
      <ButtonImage v-else :circle="true" :url="$imageAssets['btn_minus']" class="avatar-button" :image-style="{width: '88px', height: '88px'}"/>
    </div>

  </div>
</template>

<style lang="scss" scoped>
  .avatar {
    display: flex;
    justify-content: center;
    position: relative;
    height: 272px;
    flex-shrink: 0;

    svg {
      z-index:-1;
      position: absolute;
      top: 10px;
    }

    .avatar-background--circle {
      display: inline-flex;
      justify-content: center;
      border-radius: 150px;
      background: radial-gradient(50% 50.00% at 50% 50.00%, #FFF 73.44%, #E6E6E6 100%);
      width: 213px;
      height: 213px;
      cursor: pointer;

      .image {
        max-width: 213px;
        max-height: 213px;
      }

      .avatar-button {
        position: absolute;
        bottom: 25px;
        left: 0;
       }

      &.no-avatar {
        background: var(--g-4-s-schaduw-rood);
        /* inner shadow big */
        box-shadow: 8px 8px 4px 0 rgba(0, 0, 0, 0.25) inset;

        .image {
          display: none;
        }

        .avatar-button {
          left: auto;
          right: 0;
        }
      }
    }
  }
</style>