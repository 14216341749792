<script setup lang="ts">
definePageMeta({
  layout: "clean",
});
export interface SelectAvatarProperties {
  avatars: {id: number, name: string, disabled: boolean}[]
  prefix: string,
  members: number// shirt_
}

const selectedAvatar = ref<string>('')

const props = defineProps<SelectAvatarProperties>()
const emit = defineEmits(['confirm', 'cancel'])

</script>

<template>
  <div>
    <div class="page_text-container center">
      <TextHeading>Speler {{ members }}</TextHeading>
      <TextBody>Kies jouw avatar voor vandaag</TextBody>
    </div>
    <div class="page_choose-image-container">
      <div class="avatar" v-for="( avatar, index ) in avatars" :key="`avatar-radio${index}`">
        <ButtonRadio name="avatar-radio" v-model="selectedAvatar" :value="avatar.name"
                 :data="avatar" :prefix="prefix" style="width: 200px" :shadow-offset="10"/>
      </div>
<!--    <input name="avatar" type="radio" v-for="(avatar, index) in avatars" :value="avatar.id" :key="index" :disabled="avatar.disabled" @change="radioChanged = avatar.id">-->
    </div>
    <div class="page_back-container">
      <ButtonArrow direction="left"  @click="$emit('cancel')" />
    </div>
    <div class="page_action-container">
      <ButtonText v-show="selectedAvatar" size="small" label="Kies" @click="$emit('confirm', selectedAvatar)" />
    </div>
  </div>
</template>
data.state === 'active'


<style scoped>
@import "@/assets/styles/pages.css";

</style>